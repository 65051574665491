/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGetData } from '../../services/api.service';
import { API_PORTFOLIO, API_PORTFOLIO_BY_USER } from '../../constants/apiUrls';


const initialState = {
    portfolios: [],
    loading: 'idle', // 'idle' | 'pending'
    error: null
};


export const getUserPortfolios = createAsyncThunk('@portfolio/getPortfolios', async (userId, thunkAPI) => {
    
    const url = `${API_PORTFOLIO_BY_USER}/${userId}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response?.portfolio;
        return resposeData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
});

// not required... yet to remove (Minin)
export const getPortfolioByID = createAsyncThunk('@portfolio/getPortfolioByID', async (portfolioId, thunkAPI) => {
      
    const url = `${API_PORTFOLIO}/${portfolioId}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response;
        return resposeData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
});



const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserPortfolios.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getUserPortfolios.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.portfolios = action.payload
        })
        builder.addCase(getUserPortfolios.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        }) 
    }
});


// selectors
export const portfoliosSelector = (state) => state.portfolio.portfolios;


// export const {} = portfolioSlice.actions


export default portfolioSlice.reducer;