/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_FORGOT_PASSWORD, API_RESET_PASSWORD } from "../../constants/apiUrls";
import { forgotPasswordPostData, resetPasswordPostData } from "../../services/auth.service";

const initialState = {
    password: null,
    token: "",
    userId: "",

    sendForgotPasswordLink: false,
    sendForgotPasswordLinkSuccessMessage: null,
    sendForgotPasswordLinkFailureMessage: null,
};

export const forgotPassword = createAsyncThunk('@auth/forgotPassword', async (data, thunkAPI) => {
    try {
        const response = await forgotPasswordPostData(API_FORGOT_PASSWORD, data);
        const resposeData = response.data;
        if (resposeData.statusCode === 200) {
            return resposeData.response
        }
        thunkAPI.rejectWithValue(data);
    } catch (error) {
        return (thunkAPI.rejectWithValue(error.response.data.statusMessage))
    }
});

const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        clearState: (state) => {
            state.sendForgotPasswordLink = false;
            state.sendForgotPasswordLinkFailureMessage = null;
            state.sendForgotPasswordLinkSuccessMessage = null;
        },
    },
    extraReducers: (builder) => {
        //  Forgot Password
        builder.addCase(forgotPassword.pending, (state) => {
            state.sendForgotPasswordLink = false;
        })

        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.sendForgotPasswordLink = true;
            state.sendForgotPasswordLinkSuccessMessage = action.payload;
            state.token;
            state.userId;
            localStorage.setItem("token", state.token);
            localStorage.setItem("userId", state.userId);
        })

        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.sendForgotPasswordLinkFailureMessage = action.payload?.response?.email || action.payload;
            // state.sendForgotPasswordLinkFailureMessage = action.payload
        })

    }
});

export const { clearState } = passwordSlice.actions;
export const passwordSliceHandler = state => state.password;
export default passwordSlice.reducer;
