import { createTheme } from "@mui/material";

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    error: {
      main: "#DC3716",
    },
  },

  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.625em",
          marginLeft: "0px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
        
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Poppins';
            font-style: normal;
           
          }
        `,
    },
  },
});
