/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_MEETING, BASE_URL } from "../../constants/apiUrls";
import { apiDeleteData, apiGetData, apiPostData, apiUpdateData } from "../../services/api.service";

const initialState = {
  meeting: {},
  loading: "idle",
  error: null,
};

export const createMeeting = createAsyncThunk(
  "@meeting/createMeeting",
  async (payload, thunkAPI) => {
    try {
      const response = await apiPostData(`${BASE_URL}${API_MEETING}`, payload);
      return response.data.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMeetingDetails = createAsyncThunk(
  "@meeting/getMeetingDetails",
  async (payload, thunkAPI) => {
    try {
      const response = await apiGetData(`${BASE_URL}${API_MEETING}`, payload);
      return response.data.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateMeeting = createAsyncThunk(
  "@meeting/updateMeeting",
  async (payload, thunkAPI) => {
    try {
      const response = await apiUpdateData(`${BASE_URL}${API_MEETING}`, payload);
      return response.data.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteMeeting = createAsyncThunk(
  "@meeting/deleteMeeting",
  async (payload, thunkAPI) => {
    try {
      const response = await apiDeleteData(`${BASE_URL}${API_MEETING}`, payload);
      return response.data.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMeeting.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createMeeting.fulfilled, (state, action) => {
      state.loading = "idle";
      state.meeting = action.payload;
      state.error = null;
    });
    builder.addCase(createMeeting.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors

export const selectMeeting = (state) => state.meeting.meeting;
export const selectStatus = (state) => state.meeting.loading;
export const selectError = (state) => state.meeting.error;

// export const {} = teamSlice.actions

export default meetingSlice.reducer;
