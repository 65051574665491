/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_BLOG_BY_USER } from '../../constants/apiUrls';
import { getUserId } from '../../constants/helper';
import { apiGetData } from '../../services/api.service';


const initialState = {
    blogs: [],
    loading: 'idle', // 'idle' | 'pending'
    error: null
};


export const getUserblogs = createAsyncThunk('@portfolio/getblogs', async (params, thunkAPI) => {
    const userId = getUserId();
    const url = `${API_BLOG_BY_USER}/${userId}`;
    try {
        const response = await apiGetData(url,params);
        if (response.status === 200) {
            const resposeData = response.data?.response?.blogs;
            return resposeData
        }
        return [];
        
    } catch (error) {
        
        return thunkAPI.rejectWithValue(error.response.data)
    }
});






const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        deleteBlog: (state, action) => {
            const filtered = state.blogs.filter(blog => blog._id !== action.payload)
            state.blogs = filtered;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserblogs.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getUserblogs.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.blogs = action.payload
        })
        builder.addCase(getUserblogs.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        }) 
    }
});


// selectors
export const blogsSelector = (state) => state.blog.blogs;


export const {deleteBlog} = blogSlice.actions


export default blogSlice.reducer;