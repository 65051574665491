export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Authentication
export const API_LOGIN = "/CORE/public/auth/login";
export const API_REGISTER = "/CORE/public/auth/register";
export const API_FORGOT_PASSWORD = "/CORE/public/auth/forgotPassword";
export const API_RESET_FIRST_PASSWORD = "/CORE/user/resetFirstPassword";
export const API_RESET_PASSWORD = "/CORE/public/auth/resetPassword";
export const API_VERIFY_EMAIL = "/CORE/public/auth/verifyEmail";
export const API_GENERATE_OTP = "/CORE/public/auth/generateOtp";
export const API_VERIFY_OTP = "/CORE/public/auth/verifyOtp";

// file upload
export const API_FILE_UPLOAD = "/CORE/s3/uploadFiles";

// user
export const API_USER = "/CORE/user"; // both GET & POST -> append userID
export const API_GET_TEAM_MEMBERS = "/CORE/user/getTeamMembers";
export const API_ADD_TEAM_MEMBERS = "/CORE/user/addMemberToTeam";
export const API_GET_USER_DETAILS = "/CORE/user/getUserDetailsByEmailOrPhoneNumber";
export const API_SKILLS = "/CORE/skills";

// notification
export const API_NOTIFICATION = `${API_USER}/notifications`;
export const API_MARK_NOTIFICATION_READ = `CORE/notification/markNotificationAsRead`;

// portfolio
export const API_PORTFOLIO = "/CORE/portfolio";
export const API_PORTFOLIO_ADD = `${API_PORTFOLIO}`;
export const API_PORTFOLIO_BY_USER = `${API_PORTFOLIO}/user`;
export const API_COMMENT_PORTFOLIO = `${API_PORTFOLIO}/addComment`;

// blogs
export const API_BLOG = "/CORE/blog";
export const API_BLOG_BY_USER = `${API_BLOG}/user`;
export const API_COMMENT_BLOG = `${API_BLOG}/addComment`;

// tags
export const API_TAGS = "/CORE/tag";

// Vendors
export const API_VENDOR = "/CORE/vendor";
export const API_ADD_VENDOR = "/CORE/vendor/addVendor";
export const API_GET_VENDORS = "/CORE/vendor/getVendors";
export const API_GET_VENDOR_DETAIL = "/CORE/vendor/getVendorDetails";
export const API_GET_VENDOR_CATEGORIES = "/CORE/vendor/getVendorConfig";

// Leads
export const API_LEADS = "/CORE/lead";
export const API_GET_LEAD = `${API_LEADS}`;

// meetings
export const API_MEETING = "/CORE/meeting";
export const API_GET_CALENDAR = `${API_MEETING}/calendar`;

// Experts
export const API_GET_EXPERTS = "/CORE/user/getExperts";

// project
export const API_PROJECT = "/PM/project"; // both GET & POST
export const API_PROJECT_BY_STATUS = `${API_PROJECT}/getByStatus`;
export const API_UPDATE_STAGES = `${API_PROJECT}/updateStages`;
export const API_STAGE_USING_TEMPLATE = `${API_PROJECT}/generateStageUsingTemplate`;
export const API_ADD_PAYMENT_PLAN = `${API_PROJECT}/addPaymentPlan`;

// Task Checklist 
export const API_TASK_CHECKLIST = 'PM/taskChecklist';

export const API_ONGOING_PROJECTS = `${API_PROJECT}/getOngoingProjects`;
export const API_PROJECT_TIMELINE = `${API_PROJECT}/timeline`;
export const API_PROJECT_UPDATE_TIMELINE = `${API_PROJECT}/updateTimeline`;

// Templates
export const API_TEMPLATE = "PM/template"; // both GET & POST

// Project Documents
export const API_PROJECT_DIRECTORY = "/PM/project/directory";
export const API_ADD_EXTERNAL_LINK = `${API_PROJECT_DIRECTORY}/addExternalFile`;

// Checklist
// export const API_CHECKLIST = "PM/checklist";

// task
export const API_TASK = "PM/task";
export const API_ADD_ACTUAL_COST = `${API_TASK}/addActualCost`;

export const API_GET_PROJECT_COSTING = `${API_PROJECT}/getProjectCosting`;


// Inspiration board
export const API_GET_INSPIRATION_BOARD = "/CORE/board";
