/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getToken } from "src/constants/helper";
import axiosInstance from "src/utils/axiosInstance";
import {
  BASE_URL,
  API_REGISTER,
  API_LOGIN,
  API_VERIFY_EMAIL,
  API_RESET_PASSWORD,
  API_RESET_FIRST_PASSWORD,
} from "../constants/apiUrls";

export const registerUser = (data) => {
  return axios({ method: "post", url: `${BASE_URL}${API_REGISTER}`, data });
};

export const signInUser = (data) => {
  return axios({ method: "post", url: `${BASE_URL}${API_LOGIN}`, data });
};

export const verifyUser = (token) => {
  return axios({
    method: "get",
    url: `${BASE_URL}${API_VERIFY_EMAIL}/${token}`,
  });
};

export const forgotPasswordPostData = (url, body) => {
  return axiosInstance.post(url, body);
};

export const resetPasswordPostData = (token, data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}${API_RESET_PASSWORD}/${token}`,
    data,
  });
};

export const resetFirstPasswordPostData = (data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}${API_RESET_FIRST_PASSWORD}`,
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const logout = () => {
  localStorage.clear();
};
