/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BLOG_BY_USER, API_GET_LEAD, API_LEADS } from "../../constants/apiUrls";
import { getUserId } from "../../constants/helper";
import { apiGetData, apiPostData, apiUpdateData } from "../../services/api.service";

const initialState = {
  leads: [],
  statuses: [
    {
      status: "New Enquiry",
      value: "new_enquiry",
    },
    {
      status: "Consultation",
      value: "consultation",
    },
    {
      status: "Quotation",
      value: "quotation",
    },
    {
      status: "Converted",
      value: "converted",
    },
    { status: "Rejected", value: "rejected" },
  ],
  loading: "idle", // 'idle' | 'pending'
  error: null,
};

export const getLeads = createAsyncThunk("@lead/getLeads", async (params, thunkAPI) => {
  const userId = getUserId();
  const url = `${API_GET_LEAD}`;
  try {
    const response = await apiGetData(url, params);
    if (response.status === 200) {
      const resposeData = response.data?.response;
      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateLeads = createAsyncThunk("@lead/updateLeads", async ({ id, body }, thunkAPI) => {
  const url = `${API_LEADS}/${id}`;
  try {
    const response = await apiUpdateData(url, body);
    if (response.status === 200) {
      const resposeData = response.data?.response;

      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addComment = createAsyncThunk("@lead/addComment", async ({ id, body }, thunkAPI) => {
  const url = `${API_LEADS}/addComment`;
  try {
    const response = await apiPostData(url, body);
    if (response.status === 200) {
      const resposeData = response.data?.response;
      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const leadSlice = createSlice({
  name: "lead",
  initialState,
  // reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.loading = "idle";
      state.leads = action.payload;
    });
    builder.addCase(getLeads.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(updateLeads.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateLeads.fulfilled, (state, action) => {
      state.loading = "idle";
      // const mapping = state.statuses.find((si) => si.value === status);
      const currentItem = action.payload;

      const newItems = state.leads
        .filter((i) => i._id !== currentItem._id)
        .concat({ ...currentItem });
      state.leads = [...newItems];
    });
    builder.addCase(updateLeads.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(addComment.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      const updatedCurrentItem = action.payload;
      const currentItemIndex = state.leads.findIndex((i) => i._id === updatedCurrentItem._id);
      state.leads[currentItemIndex] = updatedCurrentItem;
    });
    builder.addCase(addComment.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors
export const leadsSelector = (state) => state.lead.leads;
export const leadStatuses = (state) => state.lead.statuses;

// export const {deleteBlog} = leadSlice.actions

export default leadSlice.reducer;
