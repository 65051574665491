import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_PROJECT_COSTING } from "src/constants/apiUrls";
import { apiGetData } from "src/services/api.service";


const initialState = {
    totalCount: 0,
    templates : [],
    projectCosting: {},
    actualCostTotal: 0,
    estimateCostTotal: 0,
    overallDelta: 0,
    showAllTotal: false,
    editPermission: true,
    error : null
};



export const getProjectCostingByID = createAsyncThunk('@costing/getProjectCostingByID', async (projectId, thunkAPI) => {
  const url = `${API_GET_PROJECT_COSTING}/${projectId}`;
  try {
    const response = await apiGetData(url);
    const responseData = response.data?.response;
    return responseData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
});

const checkPermission = (members, owner) => {
  const userId = localStorage.getItem('userId');
  if(owner?._id === userId) {
      return true
  }
  const member = members.find(member => member.user === userId);
  if(member && member.permission === 'edit') {
      return true;
  } 
  return false; 
}

const costingSlice = createSlice({
    name: "costing",
    initialState,
    reducers : {
      setAllTotalsData: (state, action) => {
        const {payload} = action;
        state.actualCostTotal = payload.actualCostTotal;
        state.estimateCostTotal = payload.estimateCostTotal;
        state.overallDelta = payload.overallDelta;
        state.showAllTotal = true;
      },
      removeAllTotalsData: (state) => {
        state.actualCostTotal = 0;
        state.estimateCostTotal = 0;
        state.overallDelta = 0;
        state.showAllTotal = false;
      }
    },
    extraReducers: (builder) => {

        builder.addCase(getProjectCostingByID.pending, (state) => {
            state.loading = "pending";
        })
        builder.addCase(getProjectCostingByID.fulfilled, (state, action) => {
            state.loading = "idle";
            state.projectCosting = action.payload;
            state.editPermission = checkPermission(action.payload.members, action.payload.owner);
        })
        builder.addCase(getProjectCostingByID.rejected, (state, action) => {
            state.loading = "idle";
            state.error = action.payload;
        })
    }
  });


  // selectors
    export const costingTemplatesSelector = (state) => state.costing.templates;
    export const totalCostingTemplateCount = (state) => state.costing.totalCount;
    export const projectCostingSelector = (state) => state.costing.projectCosting;
    export const actualCostTotalSelector = (state) => state.costing.actualCostTotal;
    export const estimateCostTotalSelector = (state) => state.costing.estimateCostTotal;
    export const overallDeltaSelector = (state) => state.costing.overallDelta;
    export const showAllTotalSelector = (state) => state.costing.showAllTotal;
    export const editPermissionSelector = (state) => state.costing.editPermission;

  // actions
    export const {setAllTotalsData, removeAllTotalsData} = costingSlice.actions;


  export default costingSlice.reducer;