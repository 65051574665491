export const URL_PROFILE = "/profile";
export const URL_HOME = "/home";
export const URL_REPORTS = "/reports";
export const URL_PRODUCTS = "/products";
export const URL_EXPERT_DETAILS = "/expert-details";

export const URL_NOTFOUND = "/not-found";

export const URL_LOGIN = "/login";
export const URL_LOGOUT = "logout";
export const URL_SIGNUP = "/signup";
export const URL_SEND_VERIFICATION_MAIL = "/verification-check";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/public/auth/reset-password";
export const URL_VERIFICATION_PAGE = "/public/auth/verify-email/:token";
export const URL_GENERATENEWPASSWORD = "/generate-password";
export const URL_FORGOTPASSWORDVERIFICATIONLINK = "/forgot-password-Verification";
export const URL_OTPVERIFICATION = "/otp-verification";
export const URL_SENDVERIFICATIONCODE_FORGOTPASSWORD = "/send-verification-code";

export const URL_ADD = "/add";
export const URL_EDIT = "/edit";
export const URL_DETAIL = "/detail";
export const URL_LIST = "/list";
export const PARAM_ID = "/:id";

// _______________Portfolio * PORTFOLIO______________________
export const URL_PORTFOLIO = "/portfolio";
export const URL_PORTFOLIO_DETAIL = `${URL_PORTFOLIO}${URL_DETAIL}`;
export const URL_PROFILE_ADD_PROJECT = "/profile/portfolio/:type";
export const URL_PORTFOLIO_ADD_NEW = `${URL_PORTFOLIO}${URL_ADD}`;
export const URL_PORTFOLIO_EDIT = `${URL_PORTFOLIO}${URL_EDIT}`;

// _______________Blogs * BLOGS______________________
export const URL_BLOGS = "/blogs";
export const URL_BLOG_DETAIL = `${URL_BLOGS}${URL_DETAIL}`;
export const URL_BLOG_ADD = `${URL_BLOGS}${URL_ADD}`;
export const URL_BLOG_EDIT = `${URL_BLOGS}${URL_EDIT}`;

// _______________Vendors * VENDORS______________________
export const URL_VENDOR = "/vendor";
export const URL_VENDOR_CATEGORY = `${URL_VENDOR}/category`;
export const URL_VENDOR_LIST = `${URL_VENDOR}${URL_LIST}`;

// _______________Projects * PROJECTS______________________
export const URL_PROJECT = "/projects";
export const URL_PROJECT_DETAIL = `${URL_PROJECT}${URL_DETAIL}`;
export const URL_PROJECT_ADD = `${URL_PROJECT}${URL_ADD}`;
export const URL_PROJECT_EDIT = `${URL_PROJECT}${URL_EDIT}`;

// _______________Project Management * PROJECT MANAGEMENT______________________
export const URL_DASHBOARD = "/dashboard";
export const URL_PROJECT_MANAGEMENT = "/project-management";
export const URL_PROJECT_MANAGEMENT_DETAIL = `${URL_PROJECT_MANAGEMENT}${URL_DETAIL}`;

// _______________Leads * LEADS______________________
export const URL_LEADS = "/leads";
export const URL_MEETINGS = "/meeting";
export const URL_MEETINGS_ADD = `${URL_MEETINGS}${URL_ADD}`;
export const URL_MEETINGS_EDIT = `${URL_MEETINGS}${URL_EDIT}${PARAM_ID}`;

// _______________Calendar * CALENDAR______________________
export const URL_CALENDAR = "/calendar";


// _______________Inspiration Board * INSPIRATION BOARD______________________
export const URL_INSPIRATION_BOARD = "/inpiration-board";
export const URL_INSPIRATION_BOARD_DETAIL = "/inpiration-board-detail";



