/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_GET_EXPERTS } from '../../constants/apiUrls';
import { apiGetData } from '../../services/api.service';


const initialState = {
    experts: null,
    loading: 'idle', // 'idle' | 'pending'
    error: null,
};


export const getExperts = createAsyncThunk('@user/getExperts', async (thunkAPI) => {
    const url = `${API_GET_EXPERTS}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response;
        return resposeData.experts

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
});


const expertSlice = createSlice({
    name: 'expert',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getExperts.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getExperts.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.experts = action.payload
        })
        builder.addCase(getExperts.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        })
    }
});

// selectors
export const expertsSelector = (state) => state.expert.experts;


// export const {} = expertSlice.actions
// export const { } = expertSlice.actions;


export default expertSlice.reducer;
