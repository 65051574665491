/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_GET_VENDORS, API_GET_VENDOR_CATEGORIES } from '../../constants/apiUrls';
import { apiGetData } from '../../services/api.service';

const initialState = {
    vendors: [],
    vendorCategory: {},
    loading: 'idle', // 'idle' | 'pending'
    error: null,
    category: '',
    subCategory: '',
    isUpdated: false
};


export const getVendors = createAsyncThunk('@vendor/getVendors', async (thunkAPI) => {
    const url = `${API_GET_VENDORS}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response.vendors;
        return resposeData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
    
});

export const getVendorCategory = createAsyncThunk('@vendor/getVendorCategory', async (thunkAPI) => {
    const url = `${API_GET_VENDOR_CATEGORIES}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response;
        return resposeData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.statusMessage)
    }
    
});


const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        setCategory: (state, {payload}) => {
            state.category = payload.category;
            state.subCategory = payload.subCategory;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVendors.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getVendors.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.vendors = action.payload
        })
        builder.addCase(getVendors.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;
            state.vendors = [];
        })

        builder.addCase(getVendorCategory.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getVendorCategory.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.vendorCategory = action.payload
        })
        builder.addCase(getVendorCategory.rejected, (state, action) => {
            state.loading = 'idle';
            window.console.log(action.payload)
            state.error = action.payload;
            state.vendorCategory = {}
        })
    }
});


// selectors
export const vendorsSelector = (state) => state.vendor.vendors;
export const categorySelector = (state) => state.vendor.category;
export const subCategorySelector = (state) => state.vendor.subCategory;
export const vendorCategorySelector = (state) => state.vendor.vendorCategory;

export const {setCategory} = vendorSlice.actions;


export default vendorSlice.reducer;